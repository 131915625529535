.content-container,
.nav.navbar-nav {
	opacity: 1 !important;
}


.nav.navbar-nav {
	> li {
		padding: 20px 20px;
		font-weight: bold;
	}
}